<template>
  <div class="table-container table-height table-responsive custom-scrollbar">
    <table class="table align-middle" id="table-list">
      <thead class="">
        <tr class="fs-13 fw-500 font-secondary m-0 text-nowrap">
          <th scope="col" v-for="i in data.tableHeading" :key="i">{{ i }}</th>
        </tr>
      </thead>
      <tbody class="fiat-chart">
        <template v-if="this.data.skeletonLoad">
          <tr class="align-middle" v-for="i in 5" :key="i">
            <td class="text-uppercase" v-for="j in data.tableHeading.length" :key="j">
              <VueSkeletonLoader type="react" :width="100" :height="15" animation="wave" color="#adb5bd" wave-color="#fff"
                :rounded="true" />
            </td>
          </tr>
        </template>
        <template v-if="data.balances.length > 0">
          <tr class="" v-for="(item, index) in data.balances" :key="index">
            <td>
              <div class="d-flex gap-2 align-items-center">
                <img :src="item.image" width="23">
                <h6 class="fs-15 m-0 fw-600">{{ item.symbol }}</h6>
              </div>
            </td>
            <td>
              <p class="title">{{ $helpers.NumberFixed(item.quantity, 8) }}</p>
              <span class="sub-text font-secondary">${{ $helpers.NumberFixed(item.c_bal, 4) }}</span>
            </td>
            <td>
              <p class="title">{{ $helpers.NumberFixed(0, 8) }}</p>
              <span class="sub-text font-secondary">${{ $helpers.NumberFixed(0, 4) }}</span>
            </td>
            <td>
              <p class="title">{{ $helpers.NumberFixed(item.quantity, 8) }}</p>
              <span class="sub-text font-secondary">${{ $helpers.NumberFixed(item.c_bal, 4) }}</span>
            </td>
            <td class="col-1">
              <div class="dropdown">
                <button class="btn text-primary p-0 dropdown-toggle" type="button" data-toggle="dropdown"
                  aria-expanded="false">
                  <i class="fas fa-ellipsis-h fa-xl"></i>
                </button>
                <ul class="dropdown-menu p-1">
                  <li>
    
            <router-link  class="dropdown-item py-2" v-if="item.deposit_enable == true" to="/wallet/deposit?symbol=USDT">Deposit</router-link>
                  </li>
                  <li>
                     <router-link v-if="item.withdraw_enable == true" :to="'/wallet/withdraw?symbol=' + item.symbol"
                      class="dropdown-item py-2">
                      Withdraw
                    </router-link>
                  </li>
                  <li>
                    <router-link v-if="item" :to="'/coming'" class="dropdown-item py-2">
                      Transfer
                    </router-link>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import VueSkeletonLoader from "skeleton-loader-vue";
export default {
  name: "BalancesTableList",
  components: {
    VueSkeletonLoader
  },
  props: {
    updateBalance: Function,
    data: Object,
  },
  data() {
    return {
      // loading: true,
    }
  },
  async mounted() {
    // if (this.data.balances) {
    //   this.loading = false;
    // }
  },
  computed: {
    // computeBalance() {
    //   let qty = 0;
    //   for (let item of this.data.balances) {
    //     // qty = parseFloat(item.quantity);
    //     qty = parseFloat(item.quantity) + parseFloat(item.stake_quantity);
    //   }
    //   return qty.toFixed(8);
    // },

    // computeUsd() {
    //   let usd = 0;
    //   for (let item of this.data.balances) {
    //     usd = parseFloat(item.c_bal);
    //     // usd = parseFloat(item.c_bal) + parseFloat(item.stake_bal) + parseFloat(item.fund_bal);
    //   }
    //   return usd.toFixed(4);
    // }
  },
  methods: {
    /* searchData() {
      var search = document.getElementById("flexTextCheckDefault").value.toUpperCase();
      if (search) {
        this.data.balances = this.data.res.data.data.filter((e) => e.symbol.includes(search));
      } else {
        this.data.balances = this.data.res.data.data.filter((e) => e);
        this.searchZeroData();
      }

      this.$emit('updateBalance', this.data.balances);
    },
    searchZeroData() {
      if (document.getElementById("flexSwitchCheckDefault").checked) {
        this.data.balances = this.data.res.data.data.filter(
          (e) => parseFloat(e.c_bal) != 0
        );
      } else {
        this.data.balances = this.data.res.data.data.filter((e) => e);
      }

      this.$emit('updateBalance', this.data.balances);
    }, */
  }
};
</script>
<style scoped>

.dropdown-menu {
    background-color: var(--bg5);
}
.form-check-input:checked {
  background-color: #cc9a02;
  border-color: #cc9a02;
}

#fiat-table .dropdown-menu {
  min-width: max-content;
}

.table-height {
  height: auto;
  min-height: 15rem;
  max-height: 25rem;
  overflow-y: scroll;
}

.dropdown-item:hover{
  background-color:var(--bg2);
}
.dropdown-menu li{
  margin-bottom: .3rem;
}
/* 
.table thead th {
  color: var(--text);
} */
</style>
