<template>
  <WalletLayout>
    <div class="row main-div gap-3">
      <div class="col-12">
        <div class="card rounded-4">
          <div class="card-body">
            <AvailableBalance :data="balancesData" />
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="card rounded-4">
          <div class="card-body">
            <div class="row align-items-center gap-3 flex-column flex-lg-row">
              <div class="col">
                <h6 class="title fw-600 fs-20 m-0">Balances</h6>
                <!-- <span class="sub-title font-secondary text-capitalize fs-13">Track wallet balances for effective coin
                  allocation</span> -->
              </div>
              <div class="col-lg-4 col-12">
                <div class="input-group search-input">
                  <span class="input-group-text"><i class="fa-solid fa-magnifying-glass"></i></span>
                  <input type="search" v-model="search" ref="searchInput" v-on:keyup="searchData()" placeholder="Search"
                    class="form-control ">
                </div>
              </div>
              <div class="col-12">
                <BalancesTableList :data="holdings" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>



    <!-- <div class="row portfolio-upper-row g-3">
      <div class="col-lg-8 col-12">
        <WalletBalance :wallet="wallet" />
      </div>
      <div class="col-lg-4 ps-lg-0 col-12">
        <Transaction />
      </div>
      <div class="col-lg-">
        <Balances :response="response" :balances="balances" />
      </div>
    </div> -->
  </WalletLayout>
</template>

<script>


import WalletLayout from "@/layouts/WalletLayout.vue";
import BalancesTableList from "@/components/Wallet/BalancesTableList.vue";
import AvailableBalance from "@/components/Wallet/AvailableBalance.vue";
import ApiClass from "@/api/api";
import exactMath from "exact-math";
export default {
  name: "Spot",
  components: {
    WalletLayout,
    BalancesTableList,
    AvailableBalance,
},
  data() {
    return {
      balancesData: {
        heading: 'Fiat & Spot',
        subheading: 'Available funds in your account',
        buttons: [
          { name: 'Withdraw', link: '/wallet/withdraw', class: 'text-primary border-primary' },
          { name: 'Deposit', link: '/wallet/deposit', class: 'btn-primary text-white' }
        ],
        availableQty: 0,
        availableBal: 0,
        cards: [
          { name: 'Total Available', icon: 'deposit.svg', qty: 0, bal: 0 },
          { name: 'Total Freeze', icon: 'deposit.svg', qty: 0, bal: 0 },
          { name: 'Total Balance', icon: 'deposit.svg', qty: 0, bal: 0 },
        ],
      },
      holdings: {
        type: 'spot',
        skeletonLoad: true,
        res: [],
        balances: [],
        tableHeading: ['Coin', 'Fiat & Spot', 'Freeze', 'Total', 'Operation'],
      },
      updateRes: [],
      loading: true,
      search: "",
    }
  },
  async mounted() {
    await this.callApi();
  },
  computed: {
    // total_currency_get() {
    //   // this.wallet[0].qty += item.quantity ? parseFloat(item.quantity) : 0;
    //   //     this.wallet[0].bal += item.c_bal ? parseFloat(item.c_bal) : 0;

    //   let total = 0;
    //   this.updateRes?.map((el) => {
    //     total = exactMath.add(total, el.el.quantity);
    //   });
    //   return `${total.toFixed(4)}`;
    // },
  },
  methods: {
    searchData() {
      var search = this.search.toUpperCase();
      if (search) {
        this.holdings.balances = this.updateRes.filter((e) => e.symbol.includes(search) || e.currency_type.includes(search.toLowerCase()));
      } else {
        this.holdings.balances = this.updateRes.filter((e) => e);
        // this.searchZeroData();
      }
    },
    async callApi() {
      this.holdings.res = await ApiClass.getNodeRequest("user-crypto/get", true);
      if (this.holdings.res.data.status_code == 1) {
        this.loading = false;
        this.holdings.skeletonLoad = false;

        for (const item of this.holdings.res.data.data) {
          this.balancesData.cards[0].qty += item.quantity ? parseFloat(item.quantity) : 0;
          this.balancesData.cards[0].bal += item.c_bal ? parseFloat(item.c_bal) : 0;
          // this.balancesData.cards[0].qty += item.quantity ? parseFloat(item.quantity) : 0;
          this.balancesData.cards[1].bal += item.freezed_balance ? parseFloat(item.freezed_balance) : 0;
        }
        this.balancesData.cards[1].qty = this.holdings.res.data.freezedTotal;

        this.balancesData.cards?.map((el) => {
          this.balancesData.availableQty = exactMath.add(this.balancesData.availableQty, el.qty);
          this.balancesData.availableBal = exactMath.add(this.balancesData.availableBal, el.bal);
        });

        this.balancesData.cards[2].qty = this.balancesData.availableQty;
        this.balancesData.cards[2].bal = this.balancesData.availableBal;

        this.holdings.balances = this.updateRes = this.holdings.res.data.data;
        // alert(JSON.stringify(this.holdings.res.data.data))
        // this.total_freezed = this.holdings.res.data.freezedTotal;
        // this.updateBalance(this.holdings.balances);
      }
    },
  },
};
</script>
<style scoped>
.wallet-box {
  background-color: var(--bg5);
}

/* .table-height{
  height: 100%;
  max-height: 10rem;
  overflow-y: scroll;
  overflow-x: hidden;
} */

.wallet-box .wallet_user {
  font-size: 26px;
}

@media only screen and (max-width: 767px) {
  .wallet-box .wallet_user {
    font-size: 24px;
  }

  .shadow-sm,
  .shadow-lg,
  .shadow {
    box-shadow: none !important;
  }
}
</style>